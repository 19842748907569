.modalBackground{
    width:100vh;
    height: 100vh;
    
    /* background-color: rgba(200,200,200); */
    position:absolute;
    top:0;
    left:30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.modalContainer{
    width:400px;
    height:200px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.35)0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:10px;
}
.modalContainer .title{
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}
.titleCloseBtn{
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button{
    background-color: transparent;
    border:none;
    font-size: 14px;
    cursor: pointer;
}

.modalContainer .body{
    flex:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:13px;
    text-align: center;
}

.modalContainer .footer{
flex:20%;
display:flex;
justify-content: center;
align-items: center;
}

.modalContainer .footer button{
    width:100px;
    height: auto;
    margin:10px;
    border: none;
    background-color: cornflowerblue;
    color:white;
    border-radius: 8px;
    font-size: 14px;
    cursor:pointer;
    padding:10px;
}

#cancelBtn{
    background-color: crimson;
}